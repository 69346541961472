import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { AuthenticationResult } from '@azure/msal-browser';
import { msalInstance, loginRequest } from '../../authConfig';

// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';

// constants
import { AuthActionTypes } from './constants';

const api = new APICore();

function* login(): SagaIterator {
    try {
        const result: AuthenticationResult = yield call([msalInstance, msalInstance.loginPopup], loginRequest);
        const user = result.account;
        if (user) {
            const updatedUser = { ...user, token: result.accessToken };

            api.setLoggedInUser(updatedUser);

            setAuthorization(updatedUser.token); // Use call effect
            yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, updatedUser));
        } else {
            throw new Error('Login successful, but no user account returned');
        }
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null); // Use call effect
        setAuthorization(null); // Use call effect
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    }
}

// function* fetchUserData(): SagaIterator {
//     try {
//         const accounts: AccountInfo[] = yield call([msalInstance, msalInstance.getAllAccounts]);
//         if (accounts.length === 0) {
//             throw new Error("No accounts found");
//         }
//         const account = accounts[0];

//         let tokenResponse: AuthenticationResult;
//         try {
//             tokenResponse = yield call([msalInstance, msalInstance.acquireTokenSilent], {
//                 ...loginRequest,
//                 account: account
//             });
//         } catch (error) {
//             if (error instanceof InteractionRequiredAuthError) {
//                 // fallback to interaction when silent call fails
//                 tokenResponse = yield call([msalInstance, msalInstance.acquireTokenPopup], loginRequest);
//             } else {
//                 throw error;
//             }
//         }

//         const client = Client.init({
//             authProvider: (done) => {
//                 done(null, tokenResponse.accessToken);
//             }
//         });

//         const userData = yield call([client, client.api], graphConfig.graphMeEndpoint);
//         yield put(authApiResponseSuccess(AuthActionTypes.FETCH_USER_DATA, userData));
//     } catch (error: any) {
//         yield put(authApiResponseError(AuthActionTypes.FETCH_USER_DATA, error));
//     }
// }

function* logout(): SagaIterator {
    try {
        yield call([msalInstance, msalInstance.logoutPopup]);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

// export function* watchFetchUserData() {
//     yield takeEvery(AuthActionTypes.LOGIN_USER, fetchUserData);
// }

// Keep your existing watchSignup and watchForgotPassword functions

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        // fork(watchFetchUserData),
    ]);
}

export default authSaga;
