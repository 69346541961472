// constants
import { CommunicationActionTypes } from './constants';

const INIT_STATE = {
    // common
    error: null,
    success: null,

    // data
    tags: [],
    emailsWithTags: [],

    // flags
    tagAdded: false,
    emailSent: false,

    // status
    sendingEmail: false,
    addingTag: false,
    fetchingTags: false,
    fetchingEmailsWithTags: false,
    removingTags: false,
    updatingAssociations: false,
};

type CommunicationActionType = {
    type:
        | CommunicationActionTypes.API_RESPONSE_SUCCESS
        | CommunicationActionTypes.API_RESPONSE_ERROR
        | CommunicationActionTypes.SEND_EMAIL
        | CommunicationActionTypes.GET_TAGS
        | CommunicationActionTypes.GET_EMAILS_WITH_TAGS
        | CommunicationActionTypes.ADD_TAG
        | CommunicationActionTypes.REMOVE_TAGS
        | CommunicationActionTypes.UPDATE_ASSOCIATIONS
        | CommunicationActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
};

type State = {
    // common
    error: string | null;
    success: string | null;

    // data
    tags: any[];
    emailsWithTags: any[];

    // flags
    tagAdded: boolean;
    emailSent: boolean;

    // status
    sendingEmail: boolean;
    addingTag: boolean;
    fetchingTags: boolean;
    fetchingEmailsWithTags: boolean;
    removingTags: boolean;
    updatingAssociations: boolean;
};

const Communication = (state: State = INIT_STATE, action: CommunicationActionType): any => {
    switch (action.type) {
        case CommunicationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CommunicationActionTypes.SEND_EMAIL: {
                    return {
                        ...state,
                        success: action.payload.data,
                        emailSent: true,
                        sendingEmail: false,
                        error: null,
                    };
                }

                case CommunicationActionTypes.GET_TAGS: {
                    return {
                        ...state,
                        tags: action.payload.data ? action.payload.data.tags : [],
                        fetchingTags: false,
                    };
                }

                case CommunicationActionTypes.GET_EMAILS_WITH_TAGS: {
                    return {
                        ...state,
                        fetchingEmailsWithTags: false,
                        emailsWithTags: action.payload.data ? action.payload.data.emails : [],
                    };
                }

                case CommunicationActionTypes.ADD_TAG: {
                    return {
                        ...state,
                        addingTag: false,
                        tags: [...state.tags, action.payload.data.tag],
                        tagAdded: true,
                    };
                }

                case CommunicationActionTypes.REMOVE_TAGS: {
                    return {
                        ...state,
                        removingTags: false,
                        success: action.payload.data.message,
                    };
                }

                case CommunicationActionTypes.UPDATE_ASSOCIATIONS: {
                    return {
                        ...state,
                        updatingAssociations: false,
                        success: action.payload.data.message,
                    };
                }

                default:
                    return { ...state };
            }

        case CommunicationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CommunicationActionTypes.SEND_EMAIL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        emailSent: false,
                        sendingEmail: false,
                        success: null,
                    };
                }

                case CommunicationActionTypes.GET_TAGS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        fetchingTags: false,
                    };
                }

                case CommunicationActionTypes.GET_EMAILS_WITH_TAGS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        fetchingEmailsWithTags: false,
                    };
                }

                case CommunicationActionTypes.ADD_TAG: {
                    return {
                        ...state,
                        error: action.payload.error,
                        addingTag: false,
                        tagAdded: false,
                    };
                }

                case CommunicationActionTypes.REMOVE_TAGS: {
                    return {
                        ...state,
                        removingTags: false,
                        error: action.payload.error,
                    };
                }

                case CommunicationActionTypes.UPDATE_ASSOCIATIONS: {
                    return {
                        ...state,
                        updatingAssociations: false,
                        error: action.payload.error,
                    };
                }

                default:
                    return { ...state };
            }

        case CommunicationActionTypes.GET_TAGS:
            return {
                ...state,
                fetchingTags: true,
            };

        case CommunicationActionTypes.ADD_TAG:
            return {
                ...state,
                addingTag: true,
            };

        case CommunicationActionTypes.GET_EMAILS_WITH_TAGS:
            return {
                ...state,
                fetchingEmailsWithTags: true,
            };

        case CommunicationActionTypes.SEND_EMAIL:
            return {
                ...state,
                sendingEmail: true,
            };

        case CommunicationActionTypes.REMOVE_TAGS:
            return {
                ...state,
                removingTags: true,
            };

        case CommunicationActionTypes.UPDATE_ASSOCIATIONS:
            return {
                ...state,
                updatingAssociations: true,
            };

        case CommunicationActionTypes.RESET:
            return {
                ...state,
                sendingEmail: false,
                error: null,
                emailSent: false,
                success: null,
                fetchingEmailsWithTags: false,
                fetchingTags: false,
                tagAdded: false,
                addingTag: false,
                removingTags: false,
                updatingAssociations: false,
            };
        default:
            return { ...state };
    }
};

export default Communication;
