import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { msalInstance } from './authConfig';


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={configureStore({})}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
