// constants
import { Email } from '../../models/email';
import { UpdateAssociationsRequest } from '../../models/updateAssociationsRequest';
import { CommunicationActionTypes } from './constants';

export type CommunicationActionType = {
    type:
        | CommunicationActionTypes.API_RESPONSE_SUCCESS
        | CommunicationActionTypes.API_RESPONSE_ERROR
        | CommunicationActionTypes.SEND_EMAIL
        | CommunicationActionTypes.GET_TAGS
        | CommunicationActionTypes.GET_EMAILS_WITH_TAGS
        | CommunicationActionTypes.ADD_TAG
        | CommunicationActionTypes.REMOVE_TAGS
        | CommunicationActionTypes.UPDATE_ASSOCIATIONS
        | CommunicationActionTypes.RESET;
    payload: {} | string;
};

// common success
export const communicationApiResponseSuccess = (actionType: string, data: {}): CommunicationActionType => ({
    type: CommunicationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const communicationApiResponseError = (actionType: string, error: string): CommunicationActionType => ({
    type: CommunicationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const sendEmail = (body: Email): CommunicationActionType => ({
    type: CommunicationActionTypes.SEND_EMAIL,
    payload: body,
});

export const getTags = (): CommunicationActionType => ({
    type: CommunicationActionTypes.GET_TAGS,
    payload: {},
});

export const resetComm = (): CommunicationActionType => ({
    type: CommunicationActionTypes.RESET,
    payload: {},
});

export const getEmailsWithTags = (): CommunicationActionType => ({
    type: CommunicationActionTypes.GET_EMAILS_WITH_TAGS,
    payload: {},
});

export const addTag = (name: string, description: string): CommunicationActionType => ({
    type: CommunicationActionTypes.ADD_TAG,
    payload: { name, description },
});

export const removeTags = (tags: number[]): CommunicationActionType => ({
    type: CommunicationActionTypes.REMOVE_TAGS,
    payload: { tags },
});

export const updateAssociations = (payload: UpdateAssociationsRequest): CommunicationActionType => ({
    type: CommunicationActionTypes.UPDATE_ASSOCIATIONS,
    payload: { payload },
});