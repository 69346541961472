import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
// import { APICore } from '../../helpers/api/apiCore';
import {
    sendMail,
    getTags,
    getEmailsFromTagIds,
    getAllEmailsWithTags,
    addTag,
    removeTags,
    updateAssociations,
} from '../../helpers';

// actions
import { communicationApiResponseError, communicationApiResponseSuccess } from './actions';

// constants
import { CommunicationActionTypes } from './constants';
import { Email } from '../../models/email';

type EmailData = {
    payload: Email;
    type: string;
};

type TagData = {
    payload: { name: string; description: string };
    type: string;
};

type RemoveTagsData = {
    payload: { tags: number[] };
    type: string;
};

function* sendEmailFunc({ payload, type }: EmailData): SagaIterator {
    try {
        const emails = yield call(getEmailsFromTagIds, payload.tagIds);

        if (emails.data.emails.length === 0) {
            yield put(communicationApiResponseError(type, 'No emails found for the selected tags'));
            return;
        }

        const batchSize = 500;
        const emailBatches = [];
        for (let i = 0; i < emails.data.emails.length; i += batchSize) {
            emailBatches.push(emails.data.emails.slice(i, i + batchSize));
        }

        for (const batch of emailBatches) {
            const batchPayload = { ...payload, bccRecipients: batch };
            const result = yield call(sendMail, batchPayload);
            if (result.status !== 202) {
                throw new Error(`Failed to send email batch: ${result.status}`);
            }
        }

        yield put(
            communicationApiResponseSuccess(type, {
                message: 'All emails sent successfully',
            })
        );
    } catch (error: any) {
        yield put(communicationApiResponseError(type, error.response?.data?.error?.message || error.message));
    }
}

function* getTagsFunc(): SagaIterator {
    try {
        const result = yield call(getTags);
        if (result.status === 200) {
            yield put(
                communicationApiResponseSuccess(CommunicationActionTypes.GET_TAGS, {
                    tags: result.data,
                })
            );
        }
    } catch (error: any) {
        yield put(communicationApiResponseError(CommunicationActionTypes.GET_TAGS, error.response.data.error.message));
    }
}

function* getAllEmailsWithTagsFunc(): SagaIterator {
    try {
        const result = yield call(getAllEmailsWithTags);
        if (result.status === 200) {
            yield put(
                communicationApiResponseSuccess(CommunicationActionTypes.GET_EMAILS_WITH_TAGS, {
                    emails: result.data,
                })
            );
        }
    } catch (error: any) {
        yield put(
            communicationApiResponseError(
                CommunicationActionTypes.GET_EMAILS_WITH_TAGS,
                error.response.data.error.message
            )
        );
    }
}

function* addTagFunc({ payload, type }: TagData): SagaIterator {
    try {
        const result = yield call(addTag, payload.name, payload.description);
        if (result.status === 201) {
            yield put(
                communicationApiResponseSuccess(CommunicationActionTypes.ADD_TAG, {
                    tag: result.data,
                })
            );
        }
    } catch (error: any) {
        yield put(communicationApiResponseError(type, error.response.data.error));
    }
}

function* removeTagsFunc({ payload, type }: RemoveTagsData): SagaIterator {
    try {
        const result = yield call(removeTags, payload.tags);
        if (result.status === 200) {
            yield put(
                communicationApiResponseSuccess(CommunicationActionTypes.REMOVE_TAGS, {
                    message: result.data,
                })
            );
        }
    } catch (error: any) {
        yield put(communicationApiResponseError(type, error.response.data.error));
    }
}

function* updateAssociationsFunc({ payload, type }: any): SagaIterator {
    try {
        const result = yield call(updateAssociations, payload.payload);
        if (result.status === 200) {
            yield put(
                communicationApiResponseSuccess(CommunicationActionTypes.UPDATE_ASSOCIATIONS, {
                    message: result.data,
                })
            );
        }
    } catch (error: any) {
        yield put(communicationApiResponseError(type, error.response.data.error));
    }
}

export function* watchUpdateAssociations() {
    yield takeEvery(CommunicationActionTypes.UPDATE_ASSOCIATIONS, updateAssociationsFunc);
}

export function* watchRemoveTags() {
    yield takeEvery(CommunicationActionTypes.REMOVE_TAGS, removeTagsFunc);
}

export function* watchGetEmailsWithTags(): SagaIterator {
    yield takeEvery(CommunicationActionTypes.GET_EMAILS_WITH_TAGS, getAllEmailsWithTagsFunc);
}

export function* watchSendEmail() {
    yield takeEvery(CommunicationActionTypes.SEND_EMAIL, sendEmailFunc);
}

export function* watchGetTags() {
    yield takeEvery(CommunicationActionTypes.GET_TAGS, getTagsFunc);
}

export function* watchAddTag() {
    yield takeEvery(CommunicationActionTypes.ADD_TAG, addTagFunc);
}

function* communicationSaga() {
    yield all([
        fork(watchSendEmail),
        fork(watchGetTags),
        fork(watchGetEmailsWithTags),
        fork(watchAddTag),
        fork(watchRemoveTags),
        fork(watchUpdateAssociations),
    ]);
}

export default communicationSaga;
