import { Email } from '../../models/email';
import { UpdateAssociationsRequest } from '../../models/updateAssociationsRequest';
import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

async function sendMail(params: Email) {
    const parameters = {
        message: {
            subject: params.subject,
            body: {
                contentType: 'HTML',
                content: params.body,
            },
            toRecipients: params.toRecipients.map((email) => ({
                emailAddress: { address: email },
            })),
            ccRecipients: params.ccRecipients.map((email) => ({
                emailAddress: { address: email },
            })),
            bccRecipients: params.bccRecipients.map((email) => ({
                emailAddress: { address: email },
            })),
        },
        saveToSentItems: true,
    };

    return await api.create('/me/sendMail', parameters);
}

async function getTags() {
    return await axios({
        method: 'get',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: '/communication/tags/',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

async function getEmailsFromTagIds(tagIds: number[]) {
    let queryString = '';
    if (tagIds) {
        queryString = tagIds
            ? Object.keys(tagIds)
                  .map((key: any) => 'tag_id=' + tagIds[key])
                  .join('&')
            : '';
    }

    return await axios({
        method: 'get',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: `/communication/emails-by-tags/?${queryString}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

async function getAllEmailsWithTags() {
    return await axios({
        method: 'get',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: '/communication/full-mail-list/',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

async function addTag(name: string, description: string) {
    return await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: '/communication/tags/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            'tag_name': name,
            'tag_description': description,
            'is_auto': false,
        },
    });
}

async function removeTags(tagIds: number[]) {
    return await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: '/communication/delete-tags/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            tags: tagIds,
        },
    });
}

async function updateAssociations(payload: UpdateAssociationsRequest) {
    return await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_DJANGO_API_URL,
        url: '/communication/update-associations/',
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload,
    });
}

export { sendMail, getTags, getEmailsFromTagIds, getAllEmailsWithTags, addTag, removeTags, updateAssociations };
