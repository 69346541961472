export enum CommunicationActionTypes {
    API_RESPONSE_SUCCESS = '@@communication/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@communication/API_RESPONSE_ERROR',

    SEND_EMAIL = '@@communication/SEND_EMAIL',

    GET_TAGS = '@@communication/GET_TAGS',
    GET_EMAILS_WITH_TAGS = '@@communication/GET_EMAILS_WITH_TAGS',

    ADD_TAG = '@@communication/ADD_TAG',
    REMOVE_TAGS = '@@communication/REMOVE_TAGS',
    UPDATE_ASSOCIATIONS = '@@communication/UPDATE_ASSOCIATIONS',

    RESET = '@@communication/RESET',
}
